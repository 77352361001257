import React, { useState, useEffect } from 'react'
import { Alert } from 'antd'
import { ProFormCheckbox, ProFormText, LoginForm } from '@ant-design/pro-form'
import { useAppSelector, useAppDispatch } from '~/store'
import { changeLocal } from '~/store/settings'
import { saveUser, initAccessToken } from '~/store/user'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Icon from '~/components/Icon'
import SelectLang from '~/components/SelectLang'
import i18n from '~/lang'
import { userLogin, setAccessToken } from '~/api/api'
import styles from './login.module.less'

const LoginMessage = ({ content }: { content: string }): JSX.Element => (
  <Alert
    style={{
      marginBottom: 24,
    }}
    message={content}
    type='error'
    showIcon
  />
)

interface loginParam {
  username: string
  password: string
  autoLogin: boolean
}

const Login = (): JSX.Element => {
  // const expiresTime = useAppSelector((state) => state.user.expiresIn)
  const currentLang = useAppSelector((state) => state.settings.local)
  const dispatch = useAppDispatch()
  // const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [loginError, setLoginError] = useState<boolean>(false)

  useEffect(() => {
    const expiresInStr = localStorage.getItem('expiresIn')
    if (expiresInStr) {
      const expiresIn = Number(expiresInStr)
      if (expiresIn - new Date().getTime() / 1000 > 0) {
        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
          dispatch(initAccessToken({ accessToken, expiresIn }))
          setAccessToken(accessToken)
          navigate('/', { replace: true })
        }
      }
    }
  }, [])

  // if (expiresTime - new Date().getTime() / 1000 > 0) {
  //   console.log('login success')
  //   return <Navigate to='/' state={{ from: location }} replace />
  // }

  const onFinish = async (value: loginParam) => {
    const { username, password, autoLogin } = value
    const rsp = await userLogin({ username, password, autoLogin })
    if (rsp.errcode !== 200) {
      setLoginError(true)
      return
    }

    const { accessToken, expiresIn, user } = rsp
    const { nickname, avatarUrl, phone, permission } = user

    // save accessToken and expiresIn to localStorage
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('expiresIn', String(expiresIn))

    console.log(nickname, avatarUrl, phone, permission, expiresIn, accessToken)

    dispatch(saveUser({ accessToken, expiresIn, username, nickname, avatarUrl, phone, permission }))
    setAccessToken(accessToken)

    navigate('/', { replace: true })
  }

  const onLangChange = (lang: string) => {
    dispatch(changeLocal(lang))
    if (lang === 'zhCN') {
      i18n.changeLanguage('zh-CN')
    } else if (lang === 'zhTW') {
      i18n.changeLanguage('zh-TW')
    } else if (lang === 'enUS') {
      i18n.changeLanguage('en-US')
    }
  }

  return (
    <div className={`flex flex-col h-screen bg-page-body ${styles.container}`}>
      <div className='flex flex-row justify-end'>
        <SelectLang onLangChange={onLangChange} selected={currentLang} />
      </div>
      <div className='flex-1 py-6 flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center'>
          <div className='flex flex-col items-center'>
            <div className='flex flex-row items-center'>
              <Icon type='wna-huawen' className='text-7xl text-primary-color' />
              <p className='m-0 ml-5 text-3xl text-gray-300'>WEAVE NETWORK</p>
            </div>
            <p className='m-0 text-sm text-gray-400'>{t('login.subtitle')}</p>
          </div>
          <div>
            <LoginForm
              initialValues={{
                autoLogin: false,
              }}
              submitter={{
                searchConfig: {
                  submitText: t('login.submit'),
                },
              }}
              onFinish={onFinish}
            >
              {loginError && <LoginMessage content={t('login.error')} />}
              <>
                <ProFormText
                  name='username'
                  fieldProps={{
                    size: 'large',
                    prefix: <Icon type='wna-user' className='text-xl py-1 text-neutral-300' />,
                  }}
                  placeholder={t('login.username')}
                  rules={[
                    {
                      required: true,
                      message: t('login.username.placeholder'),
                    },
                  ]}
                />
                <ProFormText.Password
                  name='password'
                  fieldProps={{
                    size: 'large',
                    prefix: <Icon type='wna-password' className='text-xl py-1 text-neutral-300' />,
                  }}
                  placeholder={t('login.password')}
                  rules={[
                    {
                      required: true,
                      message: t('login.password.placeholder'),
                    },
                  ]}
                />
              </>

              <div
                style={{
                  marginBottom: 24,
                }}
              >
                <ProFormCheckbox noStyle name='autoLogin' className='w-10'>
                  <span className='text-sm'>{t('login.auto')}</span>
                </ProFormCheckbox>
                <a
                  style={{
                    float: 'right',
                  }}
                >
                  {t('login.forget')}
                </a>
              </div>
            </LoginForm>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center mb-2 text-xs'>
        <svg
          viewBox='64 64 896 896'
          focusable='false'
          data-icon='copyright'
          width='1em'
          height='1em'
          fill='currentColor'
          aria-hidden='true'
        >
          <path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm5.6-532.7c53 0 89 33.8 93 83.4.3 4.2 3.8 7.4 8 7.4h56.7c2.6 0 4.7-2.1 4.7-4.7 0-86.7-68.4-147.4-162.7-147.4C407.4 290 344 364.2 344 486.8v52.3C344 660.8 407.4 734 517.3 734c94 0 162.7-58.8 162.7-141.4 0-2.6-2.1-4.7-4.7-4.7h-56.8c-4.2 0-7.6 3.2-8 7.3-4.2 46.1-40.1 77.8-93 77.8-65.3 0-102.1-47.9-102.1-133.6v-52.6c.1-87 37-135.5 102.2-135.5z' />
        </svg>
        <p className='m-0 p-0 ml-1'>2022 正云信息科技有限公司</p>
      </div>
    </div>
  )
}

export default Login
