import React, { useState, useEffect } from 'react'
import { Menu, Form, Input, Button, message, Modal } from 'antd'
import type { MenuProps } from 'antd'
import { useTranslation } from 'react-i18next'
import TranslateText from '~/components/TranslateText'
import { MenuInfo } from 'rc-menu/lib/interface'
import { useAppSelector, useAppDispatch } from '~/store'
import { UserInfo } from '~/model/user'
import { saveUser } from '~/store/user'
import { userUpdate, userChangePassword } from '~/api/api'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem(<TranslateText textId='settings.user.info' />, 'userInfoSettings'),
  getItem(<TranslateText textId='settings.user.changePassword' />, 'changePassword'),
]

const UserSettings = (): JSX.Element => {
  const { t } = useTranslation()
  const [menuSelect, setMenuSelect] = useState<string>('userInfoSettings')
  const dispatch = useAppDispatch()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [userInfo, setUserInfo] = useState<UserInfo>({
    id: 0,
    username: '',
    nickname: '',
    email: '',
    phone: '',
    address: '',
  })

  const userState = useAppSelector((state) => state.user)

  useEffect(() => {
    setUserInfo({
      id: 0,
      username: userState.username || '',
      nickname: userState.nickname || '',
      email: userState.email || '',
      phone: userState.phone || '',
      address: userState.address || '',
    })
  }, [userState.username, userState.nickname, userState.email, userState.phone, userState.address])

  const handleMenuSelected = ({ key }: MenuInfo): void => {
    setMenuSelect(key)
  }

  const handleUserInfoChange = (name: string, val: string) => {
    setUserInfo((state) => ({
      ...state,
      [name]: val,
    }))
  }

  const handleUserInfoSubmit = async () => {
    const ok = await userUpdate(userInfo)
    if (ok) {
      dispatch(
        saveUser({
          ...userState,
          ...userInfo,
        }),
      )
      message.success(t('settings.user.updateSuccess'))
    } else {
      message.error(t('settings.user.updateFail'))
    }
  }

  const handleChangePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      Modal.error({
        title: t('tips'),
        content: t('settings.user.confirmPasswordError'),
      })
      return
    }
    const ok = await userChangePassword({ oldPassword, newPassword, confirmPassword })
    if (ok) {
      message.success(t('settings.user.updateSuccess'))
    } else {
      message.error(t('settings.user.updateFail'))
    }
  }

  return (
    <div className='p-5 h-full'>
      <div className='flex bg-card-body min-h-full py-2'>
        <div className='flex-none w-[224px]'>
          <Menu
            items={items}
            defaultSelectedKeys={['userInfoSettings']}
            mode='inline'
            className='h-full'
            onSelect={handleMenuSelected}
          />
        </div>
        <div className='flex-auto py-3 px-8'>
          {menuSelect === 'userInfoSettings' ? (
            <div className='max-w-sm'>
              <div className='text-xl mb-5'>{t('settings.user.info')}</div>
              <Form layout='vertical'>
                <Form.Item label={t('settings.user.username')}>
                  <Input
                    placeholder={t('settings.user.usernamePlaceholder')}
                    value={userInfo.username}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('settings.user.nickname')}>
                  <Input
                    placeholder={t('settings.user.nicknamePlaceholder')}
                    value={userInfo.nickname}
                    onChange={(e) => handleUserInfoChange('nickname', e.target.value)}
                  />
                </Form.Item>
                <Form.Item label={t('settings.user.email')}>
                  <Input
                    placeholder={t('settings.user.emailPlaceholder')}
                    value={userInfo.email}
                    onChange={(e) => handleUserInfoChange('email', e.target.value)}
                  />
                </Form.Item>
                <Form.Item label={t('settings.user.phone')}>
                  <Input
                    placeholder={t('settings.user.phonePlaceholder')}
                    value={userInfo.phone}
                    onChange={(e) => handleUserInfoChange('phone', e.target.value)}
                  />
                </Form.Item>
                <Form.Item label={t('settings.user.address')}>
                  <Input
                    placeholder={t('settings.user.addressPlaceholder')}
                    value={userInfo.address}
                    onChange={(e) => handleUserInfoChange('address', e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' onClick={handleUserInfoSubmit}>
                    {t('settings.user.submit')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div className='max-w-sm'>
              <div className='text-xl mb-5'>{t('settings.user.changePassword')}</div>
              <Form layout='vertical'>
                <Form.Item label={t('settings.user.oldPassword')}>
                  <Input
                    placeholder={t('settings.user.oldPasswordPlaceholder')}
                    value={oldPassword}
                    type='password'
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label={t('settings.user.newPassword')}>
                  <Input
                    placeholder={t('settings.user.newPasswordPlaceholder')}
                    value={newPassword}
                    type='password'
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label={t('settings.user.confirmPassword')}>
                  <Input
                    placeholder={t('settings.user.confirmPasswordPlaceholder')}
                    value={confirmPassword}
                    type='password'
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' onClick={handleChangePasswordSubmit}>
                    {t('settings.user.submit')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserSettings
