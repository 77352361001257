import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SettingsState {
  local: string
}

const initialState: SettingsState = {
  local: 'zhCN',
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeLocal: (state, action: PayloadAction<string>) => ({ ...state, local: action.payload }),
  },
})

export const { changeLocal } = settingsSlice.actions

export default settingsSlice.reducer
