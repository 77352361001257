import React from 'react'
import { Menu } from 'antd'
import Icon from '~/components/Icon'
import { MenuInfo } from 'rc-menu/lib/interface'
import HeaderDropdown from './HeaderDropdown'

interface Props {
  currentLocal: string
  onLangChange: (lang: string) => void
}

const SelectLang = ({ currentLocal, onLangChange }: Props): JSX.Element => {
  const handleMenuClick = ({ key }: MenuInfo): void => {
    onLangChange(key)
  }

  const menu = (
    <Menu
      selectedKeys={[currentLocal]}
      onClick={handleMenuClick}
      items={[
        { key: 'zhCN', label: ' 简体中文 ' },
        { key: 'zhTW', label: ' 繁體中文 ' },
        { key: 'enUS', label: ' English ' },
      ]}
    />
  )

  return (
    <HeaderDropdown overlay={menu}>
      <span className='px-3 cursor-pointer hover:bg-opacity-50 hover:bg-gray-700 ml-3 flex items-center'>
        <Icon type='wna-language' className='text-xl' />
      </span>
    </HeaderDropdown>
  )
}

export default SelectLang
