const zhCN = {
  tips: '提示',
  confirm: '确认',
  cancel: '取消',
  delete: '删除',
  edit: '编辑',

  day: '天',
  hour: '时',
  minute: '分',

  'menu.user': '用户管理',
  'menu.share': '分享管理',
  'menu.userPaintingList': '画作列表',
  'menu.shareList': '分享列表',
  'menu.uncheckedShareList': '待审核列表',

  'share.unchecked.title': '待审核列表',

  'login.subtitle': '在线织带管理系统',
  'login.username': '用户名',
  'login.username.placeholder': '请输入用户名!',
  'login.password': '密码',
  'login.password.placeholder': '请输入密码!',
  'login.auto': '自动登录',
  'login.forget': '忘记密码',
  'login.error': '账户或密码错误',
  'login.submit': '登录',

  'header.userSettings': '用户设置',
  'header.logout': '退出登录',

  'settings.user.info': '用户信息',
  'settings.user.username': '用户名',
  'settings.user.usernamePlaceholder': '请输入用户名',
  'settings.user.nickname': '昵称',
  'settings.user.nicknamePlaceholder': '请输入昵称',
  'settings.user.email': '邮箱',
  'settings.user.emailPlaceholder': '请输入邮箱',
  'settings.user.phone': '电话',
  'settings.user.phonePlaceholder': '请输入电话',
  'settings.user.address': '地址',
  'settings.user.addressPlaceholder': '请输入地址',
  'settings.user.changePassword': '修改密码',
  'settings.user.oldPassword': '旧密码',
  'settings.user.oldPasswordPlaceholder': '请输入旧密码',
  'settings.user.newPassword': '新密码',
  'settings.user.newPasswordPlaceholder': '请输入新密码',
  'settings.user.confirmPassword': '确认密码',
  'settings.user.confirmPasswordPlaceholder': '请输入确认密码',
  'settings.user.confirmPasswordError': '两次输入的密码不一致',
  'settings.user.submit': '提交',
  'settings.user.updateSuccess': '修改成功',
  'settings.user.updateFail': '修改失败',
}

export default zhCN
