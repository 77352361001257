import React from 'react'

interface Props {
  type: string
  className?: string
  size?: number
  onClick?(): void
}

const Icon = ({ type, className, size, onClick }: Props): JSX.Element => (
  <i
    className={`iconfont ${type} ${className || ''}`}
    style={size ? { fontSize: `${size}px` } : {}}
    onClick={onClick}
  />
)

export default Icon
