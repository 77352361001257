import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserPermission } from '~/model/user'

export interface UserState {
  username?: string
  nickname?: string
  phone?: string
  address?: string
  email?: string
  avatarUrl?: string
  accessToken?: string
  expiresIn?: number
  permission: UserPermission
}

const initialState: UserState = {
  username: '',
  nickname: '',
  phone: '',
  address: '',
  email: '',
  avatarUrl: '',
  accessToken: '',
  expiresIn: 0,
  permission: {
    device: 0,
    product: 0,
    workTask: 0,
    settings: 0,
    account: 0,
    monitor: 0,
    overview: 0,
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<UserState>) => ({ ...state, ...action.payload }),
    logout: () => initialState,
    initAccessToken: (state, action: PayloadAction<{ accessToken: string; expiresIn: number }>) => {
      const { accessToken, expiresIn } = action.payload

      return { ...state, accessToken, expiresIn }
    },
  },
})

export const { saveUser, initAccessToken, logout } = userSlice.actions

export default userSlice.reducer
