import React from 'react'
import Icon from '~/components/Icon'
import UserDropdown from './UserDropdown'
import SelectLang from './SelectLang'

interface Props {
  nickname?: string
  currentLocal: string
  onLogout: () => void
  onLangChange: (lang: string) => void
  onUserInfoClick: () => void
}

const PageHeader = ({
  nickname,
  currentLocal,
  onLogout,
  onLangChange,
  onUserInfoClick,
}: Props): JSX.Element => (
  <div className='flex flex-row justify-between h-full items-center px-5'>
    <div className='flex flex-row items-center'>
      <Icon type='wna-huawen' className='text-3xl text-primary-color' />
      <span className='ml-2 text-base'>WEAVE NETWORK</span>
    </div>
    <div className='flex flex-row'>
      <UserDropdown
        username={nickname || '---'}
        onUserInfoClick={onUserInfoClick}
        onLogout={onLogout}
      />
      <SelectLang currentLocal={currentLocal} onLangChange={onLangChange} />
    </div>
  </div>
)

export default PageHeader
