import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  textId: string
}

const TranslateText = ({ textId }: Props): JSX.Element => {
  const { t } = useTranslation()
  return <span>{t(textId)}</span>
}

export default TranslateText
