import React from 'react'
import { Navigate } from 'react-router-dom'
import UserList from '~/pages/user/UserList'
import UncheckedShareList from '~/pages/share/UncheckedShareList'
import ShareList from '~/pages/share/ShareList'
import UserSettings from '~/pages/settings/UserSettings'
import UserPaintingList from '~/pages/share/UserPaintingList'

export interface RouteItem {
  key: string
  sidebar?: string
  hasSubMenu?: boolean
  path?: string
  exact?: boolean
  icon?: string
  content: JSX.Element
  children?: RouteItem[]
}

const Routes: RouteItem[] = [
  {
    key: 'index',
    path: '/',
    exact: true,
    content: <Navigate to='/user/list' />,
  },
  {
    key: 'user',
    sidebar: 'menu.user',
    path: '/user',
    exact: true,
    icon: 'wna-user',
    content: <Navigate to='/user/list' />,
    children: [
      {
        key: 'userList',
        path: '/user/list',
        exact: true,
        content: <UserList />,
      },
    ],
  },
  {
    key: 'share',
    sidebar: 'menu.share',
    path: '/share',
    exact: true,
    icon: 'wna-robot',
    hasSubMenu: true,
    content: <Navigate to='/share/list' />,
    children: [
      {
        key: 'userPaintingList',
        sidebar: 'menu.userPaintingList',
        path: '/share/userPaintinglist',
        exact: true,
        content: <UserPaintingList />,
      },
      {
        key: 'shareList',
        sidebar: 'menu.shareList',
        path: '/share/list',
        exact: true,
        content: <ShareList />,
      },
      {
        key: 'uncheckedShareList',
        sidebar: 'menu.uncheckedShareList',
        path: '/share/uncheckedList',
        exact: true,
        content: <UncheckedShareList />,
      },
    ],
  },
  {
    key: 'settings',
    path: '/settings',
    exact: true,
    icon: 'wna-settings',
    content: <Navigate to='/settings/system' />,
    children: [
      {
        key: 'userSettings',
        path: '/settings/user',
        exact: true,
        content: <UserSettings />,
      },
    ],
  },
]

export default Routes
