import React from 'react'
import { PageHeader } from 'antd'
import { useNavigate } from 'react-router-dom'
// import systemRoutes from '~/routes'
// import { Route } from 'antd/lib/breadcrumb/Breadcrumb'

interface Props {
  title: string
  subTitle?: string
  hasBack?: boolean
}

const ContentHeader = ({ title, subTitle, hasBack }: Props): JSX.Element => {
  const navigate = useNavigate()

  return (
    <PageHeader
      title={title}
      subTitle={subTitle}
      ghost={false}
      onBack={
        hasBack
          ? () => {
              navigate(-1)
            }
          : undefined
      }
    />
  )
}

export default ContentHeader
