import React from 'react'
import { Dropdown } from 'antd'

interface Props {
  children: JSX.Element | JSX.Element[]
  overlay: JSX.Element
}

const HeaderDropdown = ({ children, overlay }: Props): JSX.Element => (
  <Dropdown placement='bottomRight' overlay={overlay}>
    {children}
  </Dropdown>
)

export default HeaderDropdown
