import React from 'react'
import { Dropdown, Menu } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import Icon from './Icon'

interface Props {
  onLangChange: (lang: string) => void
  selected: string
}

const SelectLang = ({ onLangChange, selected }: Props): JSX.Element => {
  const onMenuClick = ({ key }: MenuInfo): void => {
    onLangChange(key)
  }

  return (
    <Dropdown
      className='mr-10 mt-2 pl-5 py-2'
      placement='bottomRight'
      overlay={
        <Menu
          selectedKeys={[selected]}
          onClick={onMenuClick}
          items={[
            { key: 'zhCN', label: ' 简体中文 ' },
            { key: 'zhTW', label: ' 繁體中文 ' },
            { key: 'enUS', label: ' English ' },
          ]}
        />
      }
    >
      <a onClick={(e) => e.preventDefault()} className='text-gray-300'>
        <Icon type='wna-language' className='text-2xl' />
      </a>
    </Dropdown>
  )
}

export default SelectLang
