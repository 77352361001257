const enUS = {
  'login.subtitle': 'Machine Network Management System',
  'login.username': 'Username',
  'login.username.placeholder': 'Please enter username!',
  'login.password': 'Password',
  'login.password.placeholder': 'Please enter password!',
  'login.auto': 'Auto login',
  'login.forget': 'Forget password',
  'login.error': 'Username or password error',
  'login.submit': 'LOGIN',

  'header.userSettings': 'User Settings',
  'header.logout': 'Logout',

  'menu.Dashboard': 'Dashboard',
  'menu.dashboard.overview': 'Overview',
  'menu.dashboard.monitor': 'Monitor',
  'menu.product': 'Product',
  'menu.product.list': 'Product list',
  'menu.device': 'Device',
  'menu.device.list': 'Device list',
  'menu.workTask': 'Work task',
  'menu.workTask.list': 'Work task list',
  'menu.systemSettings': 'System settings',
}

export default enUS
