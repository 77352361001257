const zhTW = {
  'login.subtitle': '機台網路管理系統',
  'login.username': '用戶名',
  'login.username.placeholder': '請輸入用戶名!',
  'login.password': '密碼',
  'login.password.placeholder': '請輸入密碼!',
  'login.auto': '自動登錄',
  'login.forget': '忘記密碼',
  'login.error': '賬戶或密碼錯誤',
  'login.submit': '登錄',

  'header.userSettings': '用戶設置',
  'header.logout': '退出登錄',

  'menu.Dashboard': 'Dashboard',
  'menu.Dashboard.overview': '概览',
  'menu.Dashboard.monitor': '監控',
  'menu.product': '產品管理',
  'menu.product.list': '產品列表',
  'menu.device': '設備管理',
  'menu.device.list': '設備列表',
  'menu.workTask': '工單管理',
  'menu.workTask.list': '工單列表',
  'menu.systemSettings': '系統設置',
}

export default zhTW
