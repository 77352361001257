let accessToken = ''

// export const serverDomain = 'http://127.0.0.1:20407'
export const serverDomain = ''

const baseUrl = '/weaveAdmin'
const loginUrl = `${baseUrl}/login`
const userInfoUrl = `${baseUrl}/userInfo`

const userUpdateUrl = `${baseUrl}/userUpdate`
const userChangePasswordUrl = `${baseUrl}/changePassword`

const shareUncheckListUrl = `${baseUrl}/shareUncheckList`
const shareStatusChangeUrl = `${baseUrl}/shareStatusChange`
const shareListUrl = `${baseUrl}/shareList`
const shareDeleteUrl = `${baseUrl}/shareDelete`
const userListUrl = `${baseUrl}/userList`

const userPaintingListUrl = `${baseUrl}/paintingList`
const createShareUrl = `${baseUrl}/createShare`

export const setAccessToken = (token) => {
  accessToken = token
}

export const removeAccessToken = () => {
  accessToken = ''
}

export const getAccessToken = () => (accessToken)

const request = async (url, data) => {
  const method = data ? 'POST' : 'GET'
  const headers = { 'content-type': 'application/json', Authorization: '' }
  const body = data ? JSON.stringify(data) : null

  if (accessToken !== '') {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const result = await fetch(url, {
    method,
    headers,
    body,
  })

  const response = await result.json()

  return response
}

export const userLogin = async (param) => {
  const response = await request(loginUrl, param)

  return response
}

export const userInfoGet = async () => {
  const response = await request(userInfoUrl, {})

  return response
}

export const userUpdate = async (param) => {
  const response = await request(userUpdateUrl, param)

  if (response.errcode === 200) {
    return true
  }

  return false
}

export const userChangePassword = async (param) => {
  const response = await request(userChangePasswordUrl, param)
  if (response.errcode === 200) {
    return true
  }

  return false
}

export const shareUncheckListGet = async (page, pageSize) => {
  const response = await request(shareUncheckListUrl, { page, pageSize })
  if (response.errcode === 200) {
    return { shares: response.list, pagination: response.pagination }
  }

  return null
}

export const shareStatusChange = async (id, status) => {
  const response = await request(shareStatusChangeUrl, { id, status })
  if (response.errcode === 200) {
    return true
  }

  return false
}

export const shareListGet = async (page, pageSize) => {
  const response = await request(shareListUrl, { page, pageSize })
  if (response.errcode === 200) {
    return { shares: response.shares, pagination: response.pagination }
  }
  return null
}

export const shareDelete = async (id) => {
  const response = await request(shareDeleteUrl, { id })
  if (response.errcode === 200) {
    return true
  }
  return false
}

export const userListGet = async (page, pageSize) => {
  const response = await request(userListUrl, { page, pageSize })
  if (response.errcode === 200) {
    return { users: response.users, pagination: response.pagination }
  }
  return null
}

export const userPaintingListGet = async (page, pageSize) => {
  const response = await request(userPaintingListUrl, { page, pageSize })
  if (response.errcode === 200) {
    return { paintings: response.paintings, pagination: response.pagination }
  }
  return null
}

export const shareCreate = async (paintingId, fileTmpName) => {
  const response = await request(createShareUrl, { paintingId, fileTmpName })
  if (response.errcode === 200) {
    return true
  }
  return false
}

export default {
  setAccessToken,
  removeAccessToken,
  userLogin,
  userInfoGet,
  userUpdate,
  shareUncheckListGet,
  shareStatusChange,
  shareListGet,
  shareDelete,
  userListGet,
  userPaintingListGet,
  shareCreate,
  getAccessToken
}
