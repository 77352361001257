import React from 'react'
import { Menu } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import Icon from '~/components/Icon'
import { useTranslation } from 'react-i18next'
import HeaderDropdown from './HeaderDropdown'

interface Props {
  username: string
  onLogout: () => void
  onUserInfoClick: () => void
}

const UserDropdown = ({ username, onLogout, onUserInfoClick }: Props): JSX.Element => {
  const { t } = useTranslation()

  const handleMenuClick = ({ key }: MenuInfo): void => {
    if (key === 'logout') {
      onLogout()
    } else if (key === 'userInfo') {
      onUserInfoClick()
    }
  }

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        { key: 'userInfo', label: t('header.userSettings'), icon: <Icon type='wna-user' /> },
        { key: 'logout', label: t('header.logout'), icon: <Icon type='wna-logout' /> },
      ]}
    />
  )
  return (
    <HeaderDropdown overlay={menu}>
      <div className='flex items-center px-3 cursor-pointer hover:bg-opacity-50 hover:bg-gray-700'>
        <span className='mr-1'>{username}</span>
        <Icon type='wna-down' className='text-sm text-primary-color' />
      </div>
    </HeaderDropdown>
  )
}

export default UserDropdown
