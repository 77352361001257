import React, { useState, useEffect } from 'react'
import { Table, Button, message, Avatar, Image, Modal, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ContentHeader from '~/components/ContentHeader'
import { userPaintingListGet, shareCreate, serverDomain, getAccessToken } from '~/api/api'

const pageSize = 20

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UserPaintingList = () => {
  const [paintings, setPaintings] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fileTmpName, setFileTmpName] = useState('')
  const [imageUrl, setImageUrl] = useState(null)
  const [currentId, setCurrentId] = useState(0)
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('')

  useEffect(() => {
    const fetchPaintingList = async () => {
      const rsp = await userPaintingListGet(page, pageSize)
      if (rsp) {
        setPaintings(rsp.paintings)
        setTotalPage(rsp.pagination.totalPage)
      }
    }
    setAccessToken(getAccessToken())
    fetchPaintingList()
  }, [page])

  const handleTableChange = (pagination) => {
    const { current } = pagination
    if (current) {
      setPage(current)
    }
  }

  const handleShareCreate = (paintingId) => {
    console.log('handleShareCreate')
    setIsModalOpen(true)
    setCurrentId(paintingId)
    setImageUrl(null);
  }

  const handleShareOk = async () => {
    // if (fileTmpName === '') {
    //   message.error('请先上传照片')
    //   return
    // }

    const ok = await shareCreate(currentId, fileTmpName)
    if (ok) {
      setIsModalOpen(false)
      setFileTmpName('')
      message.success('操作成功')
    } else {
      message.error('操作失败')
    }
  }

  const handleShareCancel = () => {
    setIsModalOpen(false)
    setFileTmpName('')
  }

  const columns = [
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '图片',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      render: (thumbnailUrl) => (
        <Image
          src={`${serverDomain}${thumbnailUrl}`}
          width={80}
          height={80}
          className='object-contain border-[1px] border-gray-600 border-solid'
        />
      ),
    },
    {
      title: '头像',
      dataIndex: 'avatarUrl',
      key: 'avatarUrl',
      render: (avatarUrl) => <Avatar src={avatarUrl} />,
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '创建时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
    },
    {
      title: '操作',
      key: 'operation',
      render: (text, record) => (
        <>
          <Button
            type='link'
            size='small'
            onClick={() => {
              handleShareCreate(record.id)
            }}
          >
            选为分享画作
          </Button>

          <a target="_blank" rel="noreferrer" href={record.thumbnailUrl.replace('thumbnail', 'bmp').replace('png', 'bmp')}>下载原图</a>
        </>
      ),
    },
  ]

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      if (info.file.response.errcode !== 200) {
        message.error("上传失败")
        return
      }
      setFileTmpName(info.file.response.uploadInfo.fileTmpName)
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div className='f-full'>
      <ContentHeader title='分享列表' />
      <div className='px-5 py-3'>
        <div className='bg-card-body p-5 overflow-x-auto'>
          <Table
            columns={columns}
            dataSource={paintings}
            rowKey='id'
            pagination={{
              current: page,
              total: totalPage,
              pageSize,
            }}
            onChange={handleTableChange}
          />
        </div>
        <Modal title="上传实物图" visible={isModalOpen} onOk={handleShareOk} onCancel={handleShareCancel}>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="/weaveAdmin/uploadPhoto"
            // beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{ "Authorization": `Bearer ${accessToken}` }}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Modal>
      </div>
    </div>
  )
}

export default UserPaintingList
