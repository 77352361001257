import React, { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { useAppSelector, useAppDispatch } from '~/store'
import { initAccessToken, saveUser } from '~/store/user'
import { setAccessToken, userInfoGet } from '~/api/api'
import BaseLayout from '~/pages/layout/BaseLayout'
import Login from '~/pages/login/Login'
import zhCN from 'antd/lib/locale/zh_CN'
import zhTW from 'antd/lib/locale/zh_TW'
import enUS from 'antd/lib/locale/en_US'
import { Locale } from 'antd/lib/locale-provider'
import systemRoutes from '~/routes'
import moment from 'moment'
import 'moment/locale/zh-cn'
import './App.css'

const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const expiresTime = useAppSelector((state) => state.user.expiresIn)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchUserInfo = async (accessToken: string, expiresIn: number) => {
      const rsp = await userInfoGet()
      if (rsp.errcode === 200) {
        const { user } = rsp
        const { nickname, avatarUrl, phone, username, email, address, permission } = user
        dispatch(
          saveUser({
            accessToken,
            expiresIn,
            username,
            nickname,
            avatarUrl,
            phone,
            email,
            address,
            permission,
          }),
        )
      }
    }
    if (expiresTime === 0) {
      const expiresInStr = localStorage.getItem('expiresIn')
      console.log(expiresTime)
      if (expiresInStr) {
        const expiresIn = Number(expiresInStr)
        if (expiresIn - new Date().getTime() / 1000 > 0) {
          const accessToken = localStorage.getItem('accessToken')
          if (accessToken) {
            dispatch(initAccessToken({ accessToken, expiresIn }))
            setAccessToken(accessToken)
            fetchUserInfo(accessToken, expiresIn)
          } else {
            navigate('/login', { replace: true })
          }
        } else {
          navigate('/login', { replace: true })
        }
      } else {
        navigate('/login', { replace: true })
      }
    } else {
      // settimeout remove accessToken when expiresIn
    }
  }, [expiresTime])

  // if (expiresTime - new Date().getTime() / 1000 < 0) {
  //   removeAccessToken()
  //   return <Navigate to='/login' state={{ from: location }} replace />
  // }

  return children
}

const App = (): JSX.Element => {
  const [locale, setLocale] = useState<Locale | undefined>(undefined)
  const localValue = useAppSelector((state) => state.settings.local)

  useEffect(() => {
    if (localValue === 'zhCN') {
      setLocale(zhCN)
      moment.locale('zh-cn')
    } else if (localValue === 'zhTW') {
      setLocale(zhTW)
      moment.locale('zh-tw')
    } else if (localValue === 'enUS') {
      setLocale(enUS)
      moment.locale('en-us')
    } else {
      setLocale(zhCN)
      moment.locale('zh-cn')
    }
  }, [localValue])

  return (
    <div className='App'>
      <ConfigProvider locale={locale}>
        {/* key={locale ? locale.locale : 'zhCN'} */}
        <Routes>
          <Route
            path='/'
            element={
              <RequireAuth>
                <BaseLayout />
              </RequireAuth>
            }
          >
            {systemRoutes.map((route) => {
              if (route.path) {
                return (
                  <React.Fragment key={route.key}>
                    <Route key={route.key} path={route.path} element={route.content} />
                    {route.children?.map((subRoute) => {
                      if (subRoute.path) {
                        return (
                          <Route
                            key={subRoute.key}
                            path={subRoute.path}
                            element={subRoute.content}
                          />
                        )
                      }
                      return null
                    })}
                  </React.Fragment>
                )
              }
              return null
            })}
          </Route>
          <Route path='/login' element={<Login />} />
        </Routes>
      </ConfigProvider>
    </div>
  )
}

export default App
