import React, { useState, useEffect } from 'react'
import { Table, Button, message, Avatar, Image } from 'antd'
import ContentHeader from '~/components/ContentHeader'
import { shareListGet, shareDelete, serverDomain } from '~/api/api'

const pageSize = 20

const ShareList = () => {
  const [shares, setShares] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    const fetchShareList = async () => {
      const rsp = await shareListGet(page, pageSize)
      if (rsp) {
        setShares(rsp.shares)
        setTotalPage(rsp.pagination.totalPage)
      }
    }
    fetchShareList()
  }, [page])

  const handleShareDelete = async (shareId) => {
    const ok = await shareDelete(shareId)
    if (ok) {
      setShares((state) => state.filter((share) => share.id !== shareId))
    } else {
      message.error('操作失败')
    }
  }

  const handleTableChange = (pagination) => {
    const { current } = pagination
    if (current) {
      setPage(current)
    }
  }

  const columns = [
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '图片',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      render: (thumbnailUrl) => (
        <Image
          src={`${serverDomain}${thumbnailUrl}`}
          width={80}
          height={80}
          className='object-contain border-[1px] border-gray-600 border-solid'
        />
      ),
    },
    {
      title: '头像',
      dataIndex: 'avatarUrl',
      key: 'avatarUrl',
      render: (avatarUrl) => <Avatar src={avatarUrl} />,
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '分享时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
    },
    {
      title: '操作',
      key: 'operation',
      render: (text, record) => (
        <Button
          type='link'
          size='small'
          danger
          onClick={() => {
            handleShareDelete(record.id)
          }}
        >
          删除
        </Button>
      ),
    },
  ]

  return (
    <div className='f-full'>
      <ContentHeader title='分享列表' />
      <div className='px-5 py-3'>
        <div className='bg-card-body p-5 overflow-x-auto'>
          <Table
            columns={columns}
            dataSource={shares}
            rowKey='id'
            pagination={{
              current: page,
              total: totalPage,
              pageSize,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  )
}

export default ShareList
