import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './en_US'
import zhCN from './zh_CN'
import zhTW from './zh_TW'

const resources = {
  'zh-CN': {
    translation: zhCN,
  },
  'zh-TW': {
    translation: { ...zhCN, ...zhTW },
  },
  'en-US': {
    translation: { ...zhCN, ...enUS },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'zh-CN',

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
