import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd'
import type { MenuProps } from 'antd'
import { useAppSelector, useAppDispatch } from '~/store'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { logout } from '~/store/user'
import { changeLocal } from '~/store/settings'
import Icon from '~/components/Icon'
import PageHeader from '~/components/Header'
import { removeAccessToken } from '~/api/api'
import systemRoutes from '~/routes'
import i18n from '~/lang'
import TranslateText from '~/components/TranslateText'
import { MenuInfo } from 'rc-menu/lib/interface'
import styles from './BaseLayout.module.less'

const { Header, Sider, Content } = Layout

type MenuItem = Required<MenuProps>['items'][number]

const routesPath = new Map<string, string>()
const routesKey = new Map<string, string>()

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: string,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon: icon ? <Icon type={icon} /> : undefined,
    children,
    label,
  } as MenuItem
}

const BaseLayout = (): JSX.Element => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [siderWidth, setSiderWidth] = useState<number>(208)

  const currentLang = useAppSelector((state) => state.settings.local)
  const userInfo = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [SiderSelectKey, setSiderSelectKey] = useState<string>('')
  const location = useLocation()

  const { username, nickname, permission } = userInfo

  const getMenuItems = (): MenuItem[] => {
    const menuItems: MenuItem[] = []
    routesKey.set('/', 'userList')
    systemRoutes.forEach((route) => {
      if (route.path) {
        routesPath.set(route.key, route.path)
      }
      if (route.sidebar && !route.hasSubMenu && route.path) {
        routesKey.set(route.path, route.key)
      }
      if (route.sidebar) {
        const hasPermission = true

        let item: MenuItem = null
        // route.hasSubMenu &&
        if (route.children) {
          const children: MenuItem[] = []
          route.children.forEach((child) => {
            if (child.path) {
              routesPath.set(child.key, child.path)
            }
            const hasSubPermission = true

            if (child.sidebar && hasSubPermission) {
              children.push(getItem(<TranslateText textId={child.sidebar} />, child.key))
            }
            if (child.sidebar && child.path) {
              routesKey.set(child.path, child.key)
            }
            if (!child.sidebar && child.path) {
              routesKey.set(child.path, route.key)
            }
          })
          item = getItem(
            <TranslateText textId={route.sidebar} />,
            route.key,
            route.icon,
            children.length > 0 ? children : undefined,
          )
        } else {
          item = getItem(<TranslateText textId={route.sidebar} />, route.key, route.icon)
        }
        if (hasPermission) {
          menuItems.push(item)
        }
      }
    })

    return menuItems
  }

  useEffect(() => {
    setSiderWidth(collapsed ? 48 : 208)
  }, [collapsed])

  useEffect(() => {
    const path = location.pathname
    const key = routesKey.get(path)
    if (key) {
      setSiderSelectKey(key)
    }
  }, [])

  const handleLogout = (): void => {
    // remove localStorage accessToken and expiresIn
    localStorage.removeItem('accessToken')
    localStorage.removeItem('expiresIn')
    removeAccessToken()
    dispatch(logout())
    navigate('/login', { replace: true })
  }

  const handleUserInfoClick = (): void => {
    navigate('/settings/user')
  }

  const handleLangChange = (lang: string): void => {
    dispatch(changeLocal(lang))
    if (lang === 'zhCN') {
      i18n.changeLanguage('zh-CN')
    } else if (lang === 'zhTW') {
      i18n.changeLanguage('zh-TW')
    } else if (lang === 'enUS') {
      i18n.changeLanguage('en-US')
    }
  }

  const handleMenuSelected = ({ key }: MenuInfo): void => {
    const path = routesPath.get(key)
    if (path) {
      navigate(path)
    }
    setSiderSelectKey(key)
  }

  return (
    <Layout className='h-screen bg-page-body'>
      <div
        style={{
          width: `${siderWidth}px`,
          flex: `0 0 ${siderWidth}px`,
          maxWidth: `${siderWidth}px`,
          minWidth: `${siderWidth}px`,
        }}
        className={styles.sider_placeholder}
      />
      <Sider
        className={styles.sider}
        width='208'
        collapsedWidth='48'
        collapsed={collapsed}
        collapsible
        trigger={null}
      >
        <div className='flex flex-col h-full'>
          <div className='flex-auto'>
            <Menu
              defaultSelectedKeys={['overview']}
              selectedKeys={[SiderSelectKey]}
              defaultOpenKeys={['dashboard']}
              mode='inline'
              items={getMenuItems()}
              onSelect={handleMenuSelected}
            />
          </div>
          <div
            onClick={() => {
              setCollapsed((state) => !state)
            }}
            className='flex-none border-0 border-t-gray-700 border-opacity-80 border-t-[1px] border-solid cursor-pointer hover:bg-gray-800 hover:text-primary-color'
          >
            <div className='w-[48px] h-[48px] flex flex-row justify-center items-center'>
              <Icon type={collapsed ? 'wna-menu-open' : 'wna-menu-close'} className='text-base' />
            </div>
          </div>
        </div>
      </Sider>
      <Layout>
        <div className={styles.header_placeholder} />
        <Header className={styles.header}>
          <PageHeader
            nickname={nickname && nickname !== '' ? nickname : username}
            currentLocal={currentLang}
            onLangChange={handleLangChange}
            onLogout={handleLogout}
            onUserInfoClick={handleUserInfoClick}
          />
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default BaseLayout
