// import React from 'react'
// import ReactDOM from 'react-dom/client'
// import './index.css'
// import { BrowserRouter } from 'react-router-dom'
// import { store } from '~/store'
// import { Provider } from 'react-redux'
// import './lang'
// import App from './App'
// import reportWebVitals from './reportWebVitals'

// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Provider>
//   </React.StrictMode>,
// )

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

import React from 'react'
import { render } from 'react-dom'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { store } from '~/store'
import { Provider } from 'react-redux'
import './lang'
import App from './App'

render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)
