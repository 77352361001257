import React, { useState, useEffect } from 'react'
import { Table, Tag, Avatar } from 'antd'
import ContentHeader from '~/components/ContentHeader'
import { userListGet } from '~/api/api'

const pageSize = 20

const UserList = () => {
  const [users, setUsers] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    const fetchUserList = async () => {
      const rsp = await userListGet(page, pageSize)
      if (rsp) {
        setUsers(rsp.users)
        setTotalPage(rsp.pagination.totalPage)
      }
    }
    fetchUserList()
  }, [page])

  const handleTableChange = (pagination) => {
    const { current } = pagination
    if (current) {
      setPage(current)
    }
  }

  const columns = [
    {
      title: '头像',
      dataIndex: 'avatarURL',
      key: 'avatarURL',
      render: (avatarUrl) => <Avatar src={avatarUrl} />,
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender',
      render: (gender) => {
        if (gender === 1) {
          return <Tag color='blue'>男</Tag>
        }
        if (gender === 2) {
          return <Tag color='red'>女</Tag>
        }
        return <Tag color='cyan'>未知</Tag>
      },
    },
    {
      title: '国家',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: '地区',
      dataIndex: 'province',
      key: 'province',
      render: (province, record) => `${province} ${record.city}`,
    },
  ]

  return (
    <div className='f-full'>
      <ContentHeader title='用户列表' />
      <div className='px-5 py-3'>
        <div className='bg-card-body p-5 overflow-x-auto'>
          <Table
            columns={columns}
            dataSource={users}
            rowKey='id'
            pagination={{
              current: page,
              total: totalPage,
              pageSize,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  )
}

export default UserList
